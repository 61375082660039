<template>
    <div class="container">
        <iframe
            :src="src"
            frameborder="0"
            scrolling="auto"
            allowfullscreen
            @load="onLoad"
        />
        <v-overlay :absolute="false" v-if="loading">
            <JumpingDots />
        </v-overlay>
    </div>
</template>

<script>
export default {
    components: {
        JumpingDots: () => import('./loaders/JumpingDots'),
    },
    props: {
        reportId: {
            type: String,
            required: true,
        },
        pageId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        src() {
            return `https://datastudio.google.com/embed/reporting/${this.reportId}/page/${this.pageId}`
        },
    },
    methods: {
        onLoad() {
            this.loading = false
        },
    },
}
</script>

<style scoped>
div.container {
    width: 100%;
    height: 100%;
}
iframe {
    width: 100%;
    height: 100%;
    border: none;
}
</style>
